import aboutimg from "../assets/img/aboutimg.jpg";
import article from "../assets/img/article.jpg";
import blog4 from "../assets/img/blog1.jpg";
import blog5 from "../assets/img/blog2.jpg";
import doctor1 from "../assets/img/doctor1.png";
import doctor2 from "../assets/img/doctor2.png";
import drDaniel from "../assets/img/drDaniel.jpg";
import fac1 from "../assets/img/fac1.png";
import fac2 from "../assets/img/fac2.png";
import facilityhero from "../assets/img/facilityhero.jpg";
import featurefacility from "../assets/img/featurefacility.jpg";
import heroimg from "../assets/img/heroimg.png";
import smith from "../assets/img/smith.jpg";
import bas from "../assets/img/bas.png";
import blog from "../assets/img/blog.png";
import blog2 from "../assets/img/blog2.png";
import blog3 from "../assets/img/blog3.png";
import blog1 from "../assets/img/blog1.png";
import hospital from "../assets/img/hospital.jpg";
import doctor from "../assets/img/doctor.png";
import blurhospital from "../assets/img/blurhospital.png";
import tick from "../assets/img/tick.svg";
import cancel from "../assets/img/cancel.svg";
import search from "../assets/img/search.svg";
import search1 from "../assets/img/search.png";
import filter from "../assets/img/filter.png";
import ExpertResult from "../assets/img/expertResult.png";
import varification from "../assets/img/varification.png";
import machine from "../assets/img/machine.png";
import phone from "../assets/img/telephone.png";
import chorome from "../assets/img/chorome.png";
import letter from "../assets/img/letter.png";
import transparency from "../assets/img/transparency.png";
import trust from "../assets/img/trust.png";
import men from "../assets/img/men.png";
import onestar from "../assets/img/onestar.png";
import star from "../assets/img/star.png";
import leftArrow from "../assets/img/leftArrow.png";
import rightArrow from "../assets/img/rightArrow.png";
import vectorMan from "../assets/img/VectorMan.png";
import hotDeal from "../assets/img/hotDeal.png";
import deal from "../assets/img/deal.png";
import hero from "../assets/img/homeBanner.png";
import music from "../assets/img/music.png";
import offerDetail from "../assets/img/offerDetails.png";
import hcappimage from "../assets/img/hcappimage.jpeg";

export default {
  hcappimage,
  aboutimg,
  article,
  blog1,
  blog2,
  blog3,
  blog4,
  blog5,
  deal,
  hotDeal,
  doctor1,
  doctor2,
  drDaniel,
  fac1,
  fac2,
  facilityhero,
  featurefacility,
  hero,
  heroimg,
  smith,
  bas,
  blog,
  hospital,
  doctor,
  blurhospital,
  tick,
  cancel,
  search,
  search1,
  filter,
  ExpertResult,
  varification,
  music,
  machine,
  chorome,
  phone,
  letter,
  transparency,
  men,
  offerDetail,
  onestar,
  trust,
  star,
  leftArrow,
  rightArrow,
  vectorMan,
};
