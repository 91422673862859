import { useState } from "react";
import styles from "./faq.module.scss";
import { ReactComponent as CloseSvg } from "../../assets/img/Close Circle.svg";
import { ReactComponent as MailSvg } from "../../assets/svg/Mail.svg";
import { ReactComponent as PhoneSvg } from "../../assets/svg/Calling.svg";
import { ReactComponent as LocationSvg } from "../../assets/svg/Location.svg";
import Image from "../../assets/img/FAQs-amico 1.png";

export function Faq({}) {
  return (
    <div className={styles["faq-page"]}>
      <h3>FAQs & Answers</h3>
      <h1>You have Questions and we have Answers</h1>
      <div className={styles.container}>
        <div>
          <FaqItems
            title="How can I withdraw funds?"
            description="Due to our policies, direct withdrawal from your wallet is not possible. To withdraw funds, please send an email to customerservice@hospitalsconnect.co with the following details:

            Amount to withdraw
            Wallet number
            Email address
            Phone number
            Your bank account details
            
            
            Our team will process your request, and you will receive your withdrawal 
            in 1-3 days."
          />
          <FaqItems
            title="You haven't received your order"
            description="If you haven’t received your order after the specified delivery timeframe, please send an email to customerservice@hospitalsconnect.co, specifying the issue, what was purchased, your name, email address or simply place a call to 09017085343. This will aid faster tracking."
          />
          <FaqItems
            title="What do I do when a Care agent doesn’t co-operate or is not responding"
            description="Kindly send an email to customer support at customerservice@hospitalsconnect.co to report such cases and we would be sure to investigate "
          />
          <FaqItems
            title="Pending results at a facility."
            description="If you haven’t received your result at a provided facility beyond the due date, please send an email to customerservice@hospitalsconnect.co, specifying the issue, your name, email address, the facility’s name, and the due date assigned for the result, so we can do a quick follow up.
            "
          />
          <FaqItems
            title="How long does it take for Your request to be processed"
            description="24-48hrs processing time."
          />
          <FaqItems
            title="when you haven’t gotten your request processed?"
            description="Typically our processing time is 24-48hrs, if you have not gotten your request processed kindly contact our customer support at this email customerservice@hospitalsconnect.co Or call 09017085343"
          />
          <FaqItems
            title="Issue(s) with the delivery or item purchased"
            description="If you have an issue with an item you purchased, such as a wrong item delivered or a broken seal, please call 09017085343 immediately."
          />
          <FaqItems
            title="Delivery / Shipping policy"
            description="Delivery will be completed between 1-7 business days depending on the chosen shipping method and destination. Upon placing an order, a customer representative will contact you to confirm your details. Shipping rates are calculated based on weight, shipping method, and destination. If your product arrives faulty or with a broken seal, contact us immediately."
          />
          <FaqItems
            title="I didn't get a cashback"
            description="After successfully paying a facility on the HC app, it typically takes 12-24 hours for the cashback to reflect in your account/wallet. If, after this timeframe, you still haven’t received the cashback, please send an email to customerservice@hospitalsconnect.co. Include details such as the facility, transaction date, a screenshot of the transaction on the HC app, your name, email, and wallet number."
          />
          <FaqItems
            title="What do I do if I need help at any of the partnered centers"
            description="Contact our customer support at this email customerservice@hospitalsconnect.co Or call 09017085343"
          />
        </div>
        <div>
          <img src={Image} />
        </div>
      </div>
      <div className={styles["card-container"]}>
        <FaqCard
          icon={<LocationSvg />}
          title="Visit us"
          content="15a, Idejo street, Victoria Island, Lagos.    
            Monday - Friday, 9am - 7pm EST
          "
        />
        <FaqCard
          icon={<PhoneSvg />}
          title="Call Us 24/7"
          content="+2349017085343
          "
        />
        <FaqCard
          icon={<MailSvg />}
          title="Get in touch"
          content="customercare@hospitalsconnect.co"
          color="rgba(238, 234, 253, 1)"
        />
      </div>
    </div>
  );
}

function FaqItems({ title, description }) {
  const [open, setOpen] = useState(false);
  return (
    <div
      onClick={() => setOpen((state) => !state)}
      className={open ? styles["faq-open"] : styles["faq"]}
    >
      <div className={styles["faq-header"]}>
        <h4>{title}</h4>
        <CloseSvg />
      </div>
      <div>{description}</div>
    </div>
  );
}

function FaqCard({ icon, title, content, color }) {
  return (
    <div
      style={{
        backgroundColor: color,
      }}
      className={styles["faq-card"]}
    >
      {icon}
      <div>
        <h4>{title}</h4>
        <div>{content}</div>
      </div>
    </div>
  );
}
